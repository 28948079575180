import "./Map.scss";
import React from "react";
import { GoogleMap, useLoadScript, MarkerF } from "@react-google-maps/api";
import { useContext } from "react";
import ReservationContext from "../../../context/ApartmentContext";
import google_maps_icon from "../../../assets/images/google-maps-icon.png";
import { Tooltip, useToast } from "@chakra-ui/react";

const Map: React.FC = () => {
  const infoAboutApartment = useContext(ReservationContext);
  const toast = useToast({ duration: 2000, isClosable: true });
  const getLink = () => {
    const addres_stripped = infoAboutApartment?.address.replace(/ /g, "+");
    const link = `https://www.google.pl/maps/place/${addres_stripped}`;
    return link;
  };

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY!,
  });

  if (!isLoaded) return <div>Loading...</div>;

  const copyAddress = () => {
    navigator.clipboard.writeText(infoAboutApartment?.address!);
    toast({
      title: "Skopiowano adres",
      status: "info",
    });
  };

  return (
    <div className="map-div">
      <p>
        Adres:
        <Tooltip openDelay={500} label="Kliknij aby skopowiać adres" hasArrow>
          <span onClick={() => copyAddress()}>
            {infoAboutApartment?.address!}
          </span>
        </Tooltip>
      </p>
      <GoogleMap
        center={{
          lat: infoAboutApartment?.location?.lat!,
          lng: infoAboutApartment?.location?.lng!,
        }}
        mapTypeId="satellite"
        options={{
          mapTypeControl: false,
          streetViewControl: false,
          fullscreenControl: false,
        }}
        zoom={15}
        mapContainerClassName="map-container"
      >
        <MarkerF position={infoAboutApartment?.location!} />
      </GoogleMap>
      <a
        className="gogole-maps-button"
        href={getLink()}
        target="_blank"
        rel="noreferrer"
      >
        <img src={google_maps_icon} alt="" />
        Otworz w Google Maps
      </a>
    </div>
  );
};

export default Map;
