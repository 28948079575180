import "./ModalInformation.scss";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
    Tooltip
} from "@chakra-ui/react";
import React from "react";
import {Link} from "react-router-dom";

type Props = {
  children: React.ReactElement;
};

const ModalInformation: React.FC<Props> = ({ children }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const LinkToGalery = ({children, where}: {children: React.ReactElement | string, where: string}) => {
    return (
        <Tooltip label={`Przejdz do ${children} w galeri`} openDelay={500} hasArrow>
        <Link to={"/galeria"} state={{section: where}}>{children}</Link>
        </Tooltip>
    )
  }

  return (
    <>
      {React.cloneElement(children as React.ReactElement, { onClick: onOpen })}

      <Modal isOpen={isOpen} onClose={onClose} size={"5xl"}>
        <ModalOverlay />
        <ModalContent className="modal-information-container">
          <ModalHeader>Witamy na beztroskimżyciu!</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <p>
              Witamy w strefie beztroskiego życia i wakacji na Teneryfie!
              Zapraszamy do naszych czterech wspaniałych apartamentów w <LinkToGalery where={"port-w-tajao"}> porcie Tajao </LinkToGalery>, gdzie niebo łączy się z oceanem. Nasze apartamenty oferują
              niesamowite widoki na ocean i port rybacki. Pokoje Czy apartamenty
              są czynne przez cały rok i wyposażone we wszystkie niezbędne
              urządzenia, zapewniając komfortowe życie dla trzech lub czterech
              osób. <br /> <br />
              Tutaj szum fal kołysze Cię do snu, a poranna bryza orzeźwia o
              poranku. Miejscowość ta słynie z restauracji serwujących świeże
              ryby i owoce morza, które codziennie są wyławiane przez lokalnych
              rybaków obok naszego domu. Idąc urokliwym brzegiem oceanu, gdzie
              fale rozbijają się o nadbrzeżne klify, mijamy małe rybackie
              miasteczka, gdzie można zaopatrzyć się w napoje i spróbować
              lokalnych specjałów w przyjaznych tawernach. <br /> <br />
              Teneryfa oferuje wiele atrakcji, na północy wyspy znajduje się
              stolica <LinkToGalery where={"karnawał-w-sunta-cruz"}>Santa Cruz</LinkToGalery> z ciekawą operą, słynna plaża Terasitas z
              żółtym piaskiem i palmami, szlaki piesze, średniej trudności o
              zapierających dech w piersiach widokach w <LinkToGalery where="benjo-i-taganana" >Taganana, oraz czarne
              plaże w Benijo </LinkToGalery>, w drodze powrotnej z plaży można odwiedzić
              urokliwe hiszpańskie miasteczko La Laguna. <br /> <br />
              Na południu od <LinkToGalery where={"tajao"}>Tajao</LinkToGalery> znajdują się kurorty z pięknymi plażami,
              sklepami, barami i restauracjami, takimi jak Playa de las Amerikas
              i Adeje, gdzie można bawić się do białego rana. Dalej na północ
              znajdują się olbrzymie klify w <LinkToGalery where={"los-gigantes"}>Los Gigantes</LinkToGalery> i przepastne wąwozy
              Maski. Można tam spotkać delfiny i walenie oraz podziwiać potęgę i
              ogrom tych klifów. Centralną część wyspy stanowi wygasły wulkan
              <LinkToGalery where={"pod-teide"}> Teide</LinkToGalery> (3715m), na którym można dostać się pieszo lub kolejką i
              podziwiać całą wyspę oraz otaczające ją wyspy. <br /> <br />
              Dla miłośników wodnych atrakcji polecamy Siam Park z licznych
              zjeżdżalniami i rekinami w akwariach, a także uprawianie sportów
              wodnych, takich jak surfing, kitesurfing i windsurfing w  <LinkToGalery where={"el-medano-i-montana-roja"}>El
              Medano</LinkToGalery>. Nie można też zapomnieć o wizycie w pięknym i ciekawym zoo
              <LinkToGalery where={"loro-parque"}> Loro Parque</LinkToGalery>, gdzie można zobaczyć wielu egzotycznych ptaków i
              zwierząt, a także wspaniałe pokazy z udziałem orek, delfinów i
              pingwinów. Teneryfa oferuje wiele atrakcji i form wypoczynku, a
              nasz dom leży w niedalekiej odległości od wszystkich tych miejsc,
              co czyni go jeszcze atrakcyjniejszym. <br /> <br />
              Nie czekaj dłużej, odwiedź naszą strefę beztroskiego życia i
              wakacji na Teneryfie. Lotnisko znajduje się zaledwie 15 km od
              naszego domu. Zapraszamy do naszych apartamentów, gdzie czeka na
              Ciebie niezapomniane wakacje i beztroskie życie na Teneryfie!
            </p>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalInformation;
