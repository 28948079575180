import "./ModalAboutUs.scss";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";

type Props = {
  children: React.ReactElement;
};

const ModalAboutUs: React.FC<Props> = ({ children }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      {React.cloneElement(children as React.ReactElement, { onClick: onOpen })}

      <Modal isOpen={isOpen} onClose={onClose} size={"3xl"}>
        <ModalOverlay />
        <ModalContent className="modal-about-us-container">
          <ModalHeader>O nas</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <p className="about-us-text">
              Nasze Apartamenty w Tajao, powstały z wyremontowanej "starej
              kamienicy" dzięki grupie pozytywnie zakręconych Osób związanych z
              Teneryfą. Chcemy spełniać marzenia i je spełniamy, a przy tym
              chcemy dzielić się szczęściem i radością z innymi, dlatego powstał
              obiekt beztroskiezycie.pl Mamy pasję, zainteresowania, marzenia i
              możemy dzielić się tym z innymi., zapraszamy do wynajmu, oraz na
              wiele innych atrakcji które czekają na Was Na Teneryfie i w
              których możemy Wam pomóc lub być z Wami, tj: wycieczki piesze i
              rowerowe, nurkowanie, zwiedzanie jaskiń, rejsy, kite-surfing.
              <br />
              <br />
              <span>
                Ciesz się z nami! Pozdrawiamy, zapraszamy Grupa trzymająca
                władzę nad Obiektami beztroskiezycie.pl
              </span>
            </p>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalAboutUs;
