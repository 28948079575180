import React from "react";
import "./Footer.scss";
import { Link } from "react-router-dom";
import Rules from "../../../assets/regulamin-wynajmu-apartamentu.pdf";
import ModalAboutUs from "../Modals/ModalAboutUs/ModalAboutUs";
import ModalContact from "../Modals/ModalContact/ModalContact";

const Footer: React.FC = () => {
  return (
    <footer className="page-footer-container">
      <div className="navigation">
        <ModalAboutUs>
          <p className="navigation__link">O nas</p>
        </ModalAboutUs>
        <ModalContact>
          <p className="navigation__link">Kontakt</p>
        </ModalContact>
        <a
          href={Rules}
          target="_blank"
          rel="noreferrer"
          className="navigation__link"
        >
          Regulamin
        </a>
      </div>
      <p className="copy-right">&copy; Wszelkie prawa zastrzeżone</p>
    </footer>
  );
};

export default Footer;
