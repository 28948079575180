const animationVariant = {
  visable: { opacity: 1, left: "0px" },
  hidden: { opacity: 0, left: "-150px" },
};
const animationVariantForCallendar = {
  visable: { opacity: 1, right: "0px" },
  hidden: { opacity: 0, right: "-150px" },
};

const animationOnNavigationChange = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
};

export {
  animationVariant,
  animationVariantForCallendar,
  animationOnNavigationChange,
};
