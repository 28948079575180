import React from "react";
import "./Facilities.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext } from "react";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import ReservationContext from "../../../../context/ApartmentContext";

const Facilities = () => {
  const infoAboutApartment = useContext(ReservationContext);
  const { facilities } = infoAboutApartment!;

  return (
    <div className="facilities-container">
      <h3>Rzeczy znajdujące sie w apartamencie</h3>
      <div className="facilities-div">
        {facilities.map((facilitie, index) => (
          <div className="facilitie" key={index}>
            <FontAwesomeIcon icon={faCheck} />
            <span>{facilitie}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Facilities;
