import React from "react";
import "./ButtonLoader.scss";
import { ButtonLoaderProps } from "./props";

const ButtonLoader: React.FC<ButtonLoaderProps> = (props) => {
  const { isLoading, disabled, ...HTMLprops } = props;
  return (
    <button {...HTMLprops} disabled={disabled || isLoading}>
      {isLoading ? <div className="custom-loader"></div> : props.children}
    </button>
  );
};

export default ButtonLoader;
