export const imagesAnimationSchema = (enterDelay = 0, exitDelay = 0) => {
  return {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { delay: enterDelay, duration: 0.3 } },
  };
};

export const textAnimationSchema = (enterDelay = 0, exitDelay = 0) => {
  return {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { delay: enterDelay, duration: 0.3 },
    },
  };
};
