import { useNavigate, useParams } from "react-router-dom";
import "./ReservationDetails.scss";
import { useEffect, useState } from "react";
import requestToApi from "../../components/axios";
import urls from "../../urls";
import { Tooltip, useToast } from "@chakra-ui/react";
import {
  Apartment,
  CancelRequest,
  PaymentDetails,
  Reservation,
} from "../../types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleUp,
  faBuilding,
  faCalendar,
  faCalendarAlt,
  faCheck,
  faCoins,
  faCookie,
  faEnvelope,
  faHashtag,
  faIdCard,
  faIdCardClip,
  faMoneyBill,
  faPerson,
  faPhone,
  faSignature,
  faUser,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import Spinner from "../../components/UI/Spinner/Spinner";
import ButtonLoader from "../../components/UI/ButtonLoader/ButtonLoader";
import swal from "sweetalert";
import ImageReservationNotFound from "../../assets/images/Search-amico.svg";

const ReservationDetails: React.FC = () => {
  const { uuid } = useParams<{ uuid: string }>();

  const navigate = useNavigate();
  const toast = useToast();

  const [reservationDetails, setReservationDetails] = useState<Reservation>();
  const [apartmetnDetails, setApartmentDetails] = useState<Apartment>();
  const [cancelationRequest, setCancelationRequest] = useState<CancelRequest>();
  const [paymentInfo, setPaymentInfo] = useState<PaymentDetails>();

  const [loading, setLoading] = useState(true);
  const [loadingPayment, setLoadingPayment] = useState<boolean>(false);
  const [cancelationLoading, setCancelationLoading] = useState<boolean>(false);

  const [reservationFound, setReservationFound] = useState<boolean>(true);

  const [openPersonalInfo, setOpenPersonalInfo] = useState<boolean>(true);
  const [openPayment, setOpenPayment] = useState<boolean>(false);

  const get_info_about_reservation = async () => {
    setLoading(true);
    const response = await requestToApi
      .get(urls.RESERVATION_BY_UUID(uuid!))
      .catch((err) => err.response);

    if (response.data.success) {
      setReservationDetails(response.data.reservation.reservation);
      setApartmentDetails(response.data.reservation.apartment);
      setCancelationRequest(
        response.data.reservation.reservation.cancel_request
      );
      setLoading(false);
      setReservationFound(true);
      return;
    }
    setLoading(false);
    setReservationFound(false);
  };
  useEffect(() => {
    document.title = `Rezerwacja ${uuid} | Beztroskiezycie`;
    get_info_about_reservation();
  }, [uuid]);

  useEffect(() => {
    if (!openPayment) return;

    const getPaymentInfo = async () => {
      setLoadingPayment(true);
      setPaymentInfo(undefined);
      const response = await requestToApi
        .post(urls.CHECK_PAYMENT, {
          payment: reservationDetails?.payment?.payment_id,
        })
        .catch((err) => err.response);

      if (response.data.success) {
        setPaymentInfo(response.data.details);
        setLoadingPayment(false);
        return;
      }
    };

    getPaymentInfo();
  }, [openPayment]);

  const cancel_resesrvation = async () => {
    if (!reservationDetails || cancelationRequest?.active) return;
    const result = await swal({
      title: "Jesteś pewny?",
      text: "Czy na pewno chcesz anulować rezerwację? Zwrócimy tylko 80% wpłaconej kwoty.",
      icon: "warning",
      buttons: ["Nie", "Tak"],
      dangerMode: true,
    });

    if (!result) return;

    const response = await requestToApi.post(urls.CANCEL_REQUEST, {
      reservation_uuid: reservationDetails?.uuid,
    });
    setCancelationLoading(true);

    if (response.data.success) {
      toast({
        title: "Sukces",
        description: "Wysłano prośbę o anulowanie rezerwacji",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      get_info_about_reservation();
      setCancelationLoading(false);
      return;
    }

    setCancelationLoading(true);
  };

  return (
    <div className="reservation-details-container">
      {loading ? (
        <div className="loading-div">
          <Spinner />
          <p>Szukamy Rezerwacji...</p>
        </div>
      ) : reservationFound ? (
        <>
          <div className="details-container">
            <div className="apartment-informations-container">
              <div className="box">
                <div className="apartment-image">
                  <img
                    src={urls.IMAGE(apartmetnDetails?.images[0].name!)}
                    alt=""
                    className="image"
                  />
                </div>
                <div className="apartment-info">
                  <div className="info-group">
                    <h2>
                      <FontAwesomeIcon icon={faBuilding} /> Nazwa
                    </h2>
                    <p>{apartmetnDetails?.name}</p>
                  </div>
                  <div className="info-group">
                    <h2>
                      <FontAwesomeIcon icon={faUser} /> Ilość osób
                    </h2>
                    <p>{reservationDetails?.people}</p>
                  </div>
                  <div className="info-group">
                    <h2>
                      <FontAwesomeIcon icon={faCoins} /> Cena
                    </h2>
                    <p>{reservationDetails?.price} €</p>
                  </div>
                  <div className="info-group facilities">
                    <h2>
                      <FontAwesomeIcon icon={faCookie} />
                      Co w Apartamencie
                    </h2>
                    <div className="group-of-facilities">
                      {apartmetnDetails?.facilities.map((facility) => (
                        <div className="facility">
                          <FontAwesomeIcon icon={faCheck} />
                          <p>{facility}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="reservation-details">
              <div className="container-with-info">
                <div className="reservation-info title-datas">
                  <div
                    className={`icon ${
                      reservationDetails?.isCanceled ? "canceled" : ""
                    }`}
                  >
                    <FontAwesomeIcon
                      icon={
                        reservationDetails?.isCanceled ? faXmark : faCalendar
                      }
                    />
                  </div>
                  <p className="number-of-reservation-text title-datas">
                    Rezerwacja numer {uuid}
                  </p>
                  <h1 className="name-of-apartment title-datas">
                    {apartmetnDetails?.name}
                  </h1>
                  <div className="mini-data title-datas">
                    <p>
                      <FontAwesomeIcon icon={faCalendarAlt} />{" "}
                      {moment(reservationDetails?.date_from!).format(
                        "DD/MM/YYYY 17:00"
                      )}{" "}
                      -{" "}
                      {moment(reservationDetails?.date_to!).format(
                        "DD/MM/YYYY 12:00"
                      )}
                    </p>
                  </div>
                  {reservationDetails?.created_by_admin && (
                    <div className="title-datas">
                      <Tooltip
                        label="Ta rezerwacja została założona przez administratora"
                        hasArrow
                      >
                        <div className="tag">
                          <p>
                            <FontAwesomeIcon icon={faPerson} />
                          </p>
                          <p>{reservationDetails.user_which_created}</p>
                        </div>
                      </Tooltip>
                    </div>
                  )}
                </div>
                <div className="sections-div">
                  <div className="personal-info">
                    <button
                      onClick={() => setOpenPersonalInfo((state) => !state)}
                    >
                      <div>
                        <FontAwesomeIcon icon={faIdCard} />
                        Informacje Personalne
                      </div>
                      <FontAwesomeIcon
                        className="angle"
                        icon={openPersonalInfo ? faAngleDown : faAngleUp}
                      />
                    </button>
                    {openPersonalInfo && (
                      <div className="datas">
                        <div className="info-group">
                          <h2>
                            <FontAwesomeIcon icon={faSignature} />
                            Imię i Nazwisko:
                          </h2>
                          <p>
                            {reservationDetails?.name}{" "}
                            {reservationDetails?.surname}
                          </p>
                        </div>
                        <div className="info-group">
                          <h2>
                            <FontAwesomeIcon icon={faEnvelope} />
                            Email:
                          </h2>
                          <p>{reservationDetails?.email}</p>
                        </div>
                        <div className="info-group">
                          <h2>
                            <FontAwesomeIcon icon={faPhone} />
                            Telefon:
                          </h2>
                          <p>{reservationDetails?.phone}</p>
                        </div>
                      </div>
                    )}
                  </div>
                  {!reservationDetails?.created_by_admin && (
                    <div className="payment-info">
                      <button
                        onClick={() =>
                          !loadingPayment
                            ? setOpenPayment((state) => !state)
                            : null
                        }
                      >
                        <div>
                          <FontAwesomeIcon icon={faMoneyBill} />
                          <p>Płatność</p>
                        </div>
                        <FontAwesomeIcon
                          className="angle"
                          icon={openPayment ? faAngleDown : faAngleUp}
                        />
                      </button>
                      {openPayment &&
                        (loadingPayment ? (
                          <div className="loading-container">
                            <Spinner />
                            <p>Prosze Czekać</p>
                          </div>
                        ) : (
                          <div className="datas">
                            <div className="info-group">
                              <h2>
                                <FontAwesomeIcon icon={faHashtag} /> ID
                                Płatności
                              </h2>
                              <p>{paymentInfo?.payment_id}</p>
                            </div>
                            <div className="info-group">
                              <h2>
                                <FontAwesomeIcon icon={faIdCardClip} /> Numer
                                Karty
                              </h2>
                              <div className="card-details">
                                <div className="small-info">
                                  <p className="title">Numer Karty</p>
                                  <p className="data">
                                    {" "}
                                    **** **** **** {paymentInfo?.last_4_digits}
                                  </p>
                                </div>
                                <div className="small-info">
                                  <div className="title"> Data wyczerpania</div>
                                  <p className="data">
                                    {paymentInfo?.exp_month} /{" "}
                                    {paymentInfo?.exp_year}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  )}
                  {!reservationDetails?.isCanceled ? (
                    <Tooltip
                      label={
                        cancelationRequest
                          ? "Prośba o rezerwacje została wysłana"
                          : ""
                      }
                      hasArrow
                    >
                      <div className="cancel-reservation">
                        <ButtonLoader
                          isLoading={cancelationLoading}
                          disabled={cancelationRequest?.active}
                          className="button-cancel-reservation"
                          onClick={() => cancel_resesrvation()}
                        >
                          Anuluj Rezerwacje
                        </ButtonLoader>
                      </div>
                    </Tooltip>
                  ) : (
                    <p className="canceled-reservation-info">
                      <FontAwesomeIcon icon={faXmark} />
                      Ta rezerwacja została anulowana
                      <FontAwesomeIcon icon={faXmark} />
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="reservation-not-found">
          <h1>Przepraszamy, nie znaleźliśmy takiej rezerwacji</h1>
          <img src={ImageReservationNotFound} alt="Not found :(" />
        </div>
      )}
    </div>
  );
};

export default ReservationDetails;
