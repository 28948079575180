import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CardDetails from "./CardDetails";
const promise = loadStripe(
  "pk_test_51Mb5vBKiBLS7t0JNE0C2GyEiYSCeenffDBEEwxFXTGodLJSCYpc7IflyugsLUR4infmpnQ2CIejbwwyE7p754W3O00Ibe8amUV"
);
const StripeWrapper: React.FC = () => {
  return (
    <>
      <Elements stripe={promise}>
        <CardDetails />
      </Elements>
    </>
  );
};

export default StripeWrapper;
