import "./MenuHamburger.scss";
import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleUp,
  faBars,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { NavLink, useLocation } from "react-router-dom";
import { Props } from "./props";
const MenuHamburger: React.FC<Props> = ({ list_of_apartments }) => {
  const [open, setOpen] = useState(false);
  const [openSubmenu, setOpenSubmenu] = useState(false);
  const variants = {
    open: { height: "auto", padding: "20px" },
    closed: { height: "0px", padding: 0 },
  };
  const location = useLocation();

  useEffect(() => {
    const clickHandler = (e: MouseEvent) => {
      if (open && e.target instanceof HTMLAnchorElement) {
        setOpen(false);
        setOpenSubmenu(false);
      }
    };

    document.addEventListener("click", clickHandler);

    return () => {
      document.removeEventListener("click", clickHandler);
    };
  });

  return (
    <div className="menu-hamburger-container">
      <button onClick={() => setOpen(!open)}>
        <FontAwesomeIcon icon={open ? faXmark : faBars} />
      </button>
      <motion.div
        className="menu-box"
        variants={variants}
        animate={open ? "open" : "closed"}
        initial="closed"
        transition={{ duration: 0.5 }}
      >
        {open && (
          <div className="items-list">
            <NavLink className={`menu-link`} to="/">
              Strona Główna
            </NavLink>
            <NavLink className={`menu-link`} to="/galeria">
              Galeria
            </NavLink>
            <button
              className={`submenu-button`}
              onClick={() => setOpenSubmenu((state) => !state)}
            >
              Apartementy{" "}
              <FontAwesomeIcon icon={openSubmenu ? faAngleUp : faAngleDown} />
            </button>
            {openSubmenu && (
              <div className="apartments-container">
                {list_of_apartments?.map((apartment, index) => {
                  const { id, name } = apartment;
                  return (
                    <NavLink
                      className="apartment"
                      key={index}
                      to={`/apartament/${id}`}
                    >
                      {name}
                    </NavLink>
                  );
                })}
              </div>
            )}
            <NavLink
              className="button-reservation"
              to={`/apartament/${list_of_apartments[0].id}`}
            >
              Rezerwuj
            </NavLink>
          </div>
        )}
      </motion.div>
    </div>
  );
};

export default MenuHamburger;
