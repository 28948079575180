import "./Carousel.scss";
import React, { memo, useEffect } from "react";
import { useState } from "react";
import { useMediaQuery } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { AnimatePresence, motion } from "framer-motion";
import { Props } from "./interfaces";
const GaleryCarousel: React.FC<Props> = (props) => {
  const children = props.children;
  const interval = props.interval || 5000;
  const autoSkip = props.autoSkip || true;
  const [imageNumber, setImageNumber] = useState(0);
  const [isPhone] = useMediaQuery("(max-width: 650px)");
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const timer =
      autoSkip &&
      setTimeout(() => {
        setImageNumber(
          imageNumber >= children?.length! - 1 ? 0 : imageNumber + 1
        );
      }, interval);
    return () => clearTimeout(timer);
  }, [imageNumber]);
  const classOfButtons = isPhone ? "show" : "hide";
  if (!(children?.length! >= 1)) {
    return <h1>Nie</h1>;
  }
  return (
    <div className="carousel-by-me">
      <div className="main-image">
        <AnimatePresence mode="wait">
          <motion.img
            initial={{ opacity: 0.3 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0.3 }}
            loading="lazy"
            transition={{ duration: 0.3 }}
            src={children?.[imageNumber]?.props.src}
            alt=""
            key={imageNumber}
          />
        </AnimatePresence>
        <div className="buttons">
          <button
            className={classOfButtons}
            onClick={() =>
              setImageNumber(
                imageNumber - 1 < 0 ? children?.length! - 1 : imageNumber - 1
              )
            }
          >
            <FontAwesomeIcon icon={faAngleLeft} />
          </button>
          <button
            className={classOfButtons}
            onClick={() =>
              setImageNumber(
                imageNumber >= children?.length! - 1 ? 0 : imageNumber + 1
              )
            }
          >
            <FontAwesomeIcon icon={faAngleRight} />
          </button>
        </div>
      </div>
      {!isPhone && (
        <div className="mineture">
          {children?.map((element, index: number) => {
            return (
              <div
                key={index}
                className={`opro ${index === imageNumber ? "current" : ""}`}
                onClick={() => setImageNumber(index)}
              >
                {element}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
export default memo(GaleryCarousel);
