import config from "./config.json";

const urls = {
  APARTMENTS_IMAGES: "/apartments_images",
  PRICE_OF_APARTMENT: "/get_price_of_apartment",
  PROMOCODE: "/promocode",
  APARTMENT_BY_ID: (id: number) => `/apartament/${id}`,
  CHECK_RESERVATION: (id: number) => `/checkReservation-avilvable/${id}`,
  MAKE_RESERVATION: (id: number) => `/do-reservation/${id}`,
  IMAGE: (name: string) =>
    `${
      config.mode === "test" ? config.urls.test : config.urls.prod
    }/images/${name}`,
  IMAGES: "/images",
  SEND_OTP: "/create_otp",
  VERIFY_OTP: "/check_otp",
  CHECKOUT: "/api/checkout",
  CHECK_PAYMENT: "/api/check_payment",
  RESERVATION_BY_UUID: (uuid: string) => `/reservation/${uuid}`,
  CANCEL_REQUEST: `/cancel_request`,
  CANCEL_RESERVATION: `/anuluj-rezerwacje`,
};

export default urls;
