import React from "react";
import BasicInformations from "./Pages/BasicInformations/BasicInformations";
import Facilities from "./Pages/Facilities/Facilities";
import Map from "./Pages/Map";
import Price from "./Pages/Price/Price";

const PageController: React.FC<{ page: string }> = ({ page }) => {
  switch (page) {
    case "map":
      return <Map />;
    case "price":
      return <Price />;
    case "facilities":
      return <Facilities />;
    case "basic_informations":
      return <BasicInformations />;
    default:
      return <Map />;
  }
};

export default PageController;
