import Joi from "joi";

export const formSchema = {
  name: Joi.string().required().min(3).max(30).messages({
    "string.empty": "Imię jest wymagane",
    "string.min": "Imię musi mieć minimum 3 znaki",
    "string.max": "Imię musi mieć maksymalnie 30 znaków",
  }),
  surname: Joi.string().required().min(3).max(30).messages({
    "string.empty": "Nazwisko jest wymagane",
    "string.min": "Nazwisko musi mieć minimum 3 znaki",
    "string.max": "Nazwisko musi mieć maksymalnie 30 znaków",
  }),
  phone: Joi.string().required().min(9).max(9).messages({
    "string.empty": "Numer telefonu jest wymagany",
    "string.min": "Numer telefonu musi mieć 9 znaków",
    "string.max": "Numer telefonu musi mieć 9 znaków",
  }),
  numberOfResidents: Joi.number().required().min(1).max(10).messages({
    "number.empty": "Liczba osób jest wymagana",
    "number.min": "Liczba osób musi być większa od 0",
    "number.max": "Liczba osób musi być mniejsza od 10",
    "number.base": "Liczba osób musi być liczbą",
  }),
  email: Joi.string()
    .required()
    .email({ tlds: { allow: false } })
    .messages({
      "string.empty": "Email jest wymagany",
      "string.email": "Email musi być poprawny",
    }),
};
