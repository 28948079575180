import "./Galery.scss";
import { useEffect, useState } from "react";
import requestToApi from "../../components/axios";
import urls from "../../urls";
import { ApartmentImageType, CategoriesImageType } from "../../types";
import FullImage from "../../components/UI/FullImage/FullImage";
import {useLocation} from "react-router-dom";

const Galery: React.FC = () => {
    const location = useLocation();
  const [loading, setLoading] = useState(true);

  const [apartmentImages, setApartmentImages] =
    useState<ApartmentImageType[]>();
  const [categoryImages, setCategoryImages] = useState<CategoriesImageType[]>();

  useEffect(() => {
    const getImages = async () => {
      const response = await requestToApi
        .get(urls.IMAGES)
        .catch((err) => err.response);

      if (response?.data.success) {
        setApartmentImages(response.data.images_data.apartments.data);
        setCategoryImages(response.data.images_data.categories.data);

        setLoading(false);
      }
    };
    getImages();
  }, []);

    useEffect(() => {
        if (loading) return
        if (location.state) {
            const section = location.state.section;
            console.log(section)
            const element = document.getElementById(section);
            console.log(element)
            if (element) {
                element.scrollIntoView();
            }
        }
    }, [loading]);

  return (
    <div className="galery-container">
      <div className="galery-header">
        <h1>Galeria</h1>
        <p>
          Witaj w naszej galerii, gdzie przyroda Tajao ożywa na zdjęciach!
          Odkryj niezwykłe widoki tego miejsca utrwalone w obiektywie naszych
          aparatów. Spacerując przez nasze fotografie, poznasz urokliwe
          horyzonty, spokój plaż oblewanych błękitem oceanu oraz majestatyczne
          wzgórza, które malują krajobraz tego niezwykłego regionu. Zdjęcia,
          które znajdziesz tutaj, ukazują prostotę i piękno natury, zapraszając
          Cię do podziwiania widoków, które zachwycają swoją autentycznością.
          Przekonaj się, jak przyroda Tajao staje się głównym bohaterem naszych
          fotografii. Zapraszamy do odkrywania Tajao poprzez obiektyw naszych
          aparatów!
        </p>
      </div>
      <div className="categories-list">
        {!loading &&
          categoryImages?.map((category, index) => (
            <div className="category">
              <h2 id={category.name.toLowerCase().replaceAll(" ", "-")}>{category.name}</h2>
              <div className="images">
                <FullImage>
                  {category.images.map((image, index) => (
                    <img
                      key={index}
                      loading="lazy"
                      src={urls.IMAGE(image.name)}
                      alt=""
                      className="image"
                    />
                  ))}
                </FullImage>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Galery;
