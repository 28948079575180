/* eslint-disable no-lone-blocks */
/* eslint-disable react-hooks/exhaustive-deps */
import "./Reservation.scss";
import "./CallendarStyle.css";
import React, { useState, useEffect } from "react";
import {
  Calendar,
  DayRange,
} from "@amir04lm26/react-modern-calendar-date-picker";
import { GaleryCarousel, ModalReservation } from "../../components";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import axios from "axios";
import moment, { Moment } from "moment/moment";
import TransitionPage from "../../components/Transition/Transition";
import Header from "../../components/UI/Header/header";
import {
  Skeleton,
  SkeletonText,
  useMediaQuery,
  useToast,
} from "@chakra-ui/react";
import {
  animationVariant,
  animationVariantForCallendar,
  animationOnNavigationChange,
} from "./animationConfigs";
import ApartmentContext from "../../context/ApartmentContext";
import PageController from "./PageController";
import requestToApi from "../../components/axios";
import urls from "../../urls";
import { Dates } from "../Checkout/interfaces";
import { Apartment } from "../../types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
const Reservation: React.FC = () => {
  const current_date = () => {
    const date = moment();
    return {
      year: date.year(),
      month: date.month() + 1,
      day: date.date(),
    };
  };
  const params = useParams();
  const id: Number = Number(params.id);
  const navigateTo = useNavigate();
  const toast = useToast({ isClosable: true });
  const [loading, setLoading] = useState(true);
  const [reservationDates, setReservationDates] = useState<
    Array<{
      year: number;
      month: number;
      day: number;
    }>
  >();
  const [errorMessage, setErrorMessage] = useState("");
  const [priceOfReservation, setPriceOfReservation] = useState(0);
  const [infoAboutApartment, setInfoAboutApartment] =
    useState<Apartment | null>(null);
  const [page, setPage] = useState("map");
  const [selectedDayRange, setSelectedDayRange] = useState<DayRange>({
    from: current_date(),
    to: current_date(),
  });
  const navigate = useNavigate();
  const [isSmallScreen] = useMediaQuery("(max-width: 500px)");

  const pageChoose = [
    {
      title: "Mapa",
      pageStatement: "map",
    },
    {
      title: "Podstawowe informacje",
      pageStatement: "basic_informations",
    },
    {
      title: "Cennik",
      pageStatement: "price",
    },
    {
      title: "Udogodnienia",
      pageStatement: "facilities",
    },
  ];

  useEffect(() => {
    document.title = "Ładowanie...";
    const apartmentsInfoGet = async () => {
      setLoading(true);
      const reservationsDatesFetch = await requestToApi
        .get(urls.APARTMENT_BY_ID(Number(id)))
        .catch((err) => err.response);
      if (reservationsDatesFetch.data.success) {
        setInfoAboutApartment(reservationsDatesFetch.data.apartment);
        document.title =
          reservationsDatesFetch.data.apartment.name + " | Beztroskiezycie";
        setLoading(false);
      } else {
        toast({
          title: "Błąd",
          description: reservationsDatesFetch.data.message,
          status: "error",
        });
        navigateTo("/");
        return;
      }
      const getDaysBetweenDates = (startDate: Moment, endDate: Moment) => {
        const now = startDate.clone(),
          dates = [];
        while (now.isSameOrBefore(endDate)) {
          dates.push(now.format("DD/MM/YYYY"));
          now.add(1, "days");
        }
        dates.splice(0, 1);
        return dates;
      };

      const reservations: Dates[] = reservationsDatesFetch.data.reservations;

      const listkajakas: Array<{
        year: number;
        month: number;
        day: number;
      }> = [];

      const list_of_reservation = reservations
        .map((element) => {
          const { date_from, date_to } = element;
          const moment_date_from = moment(date_from);
          const moment_date_to = moment(date_to);
          return { moment_date_from, moment_date_to };
        })
        .sort((a, b) => a.moment_date_from.diff(b.moment_date_from));
      {
        list_of_reservation.forEach((element, index) => {
          const { moment_date_from, moment_date_to } = element;
          let dateList = getDaysBetweenDates(moment_date_from, moment_date_to);
          try {
            if (
              moment_date_to.format("DD/MM/YYYY") ===
              moment(list_of_reservation[index + 1].moment_date_from).format(
                "DD/MM/YYYY"
              )
            ) {
              const formated = moment_date_to.format("DD/MM/YYYY").split("/");
              listkajakas.push({
                year: parseInt(formated[2]),
                month: parseInt(formated[1]),
                day: parseInt(formated[0]),
              });
            }
          } catch {}
          const dataList = () => {
            const list: Array<{
              year: number;
              month: number;
              day: number;
            }> = [];
            dateList.forEach((element, index) => {
              const splitElement = element.split("/");
              list.push({
                year: parseInt(splitElement[2]),
                month: parseInt(splitElement[1]),
                day: parseInt(splitElement[0]),
              });
            });
            return list;
          };
          dataList().forEach((element) => {
            listkajakas.push(element);
          });
        });

        setReservationDates(listkajakas);
      }
    };
    apartmentsInfoGet();
  }, [id]);

  const checkIfCan = () => {
    const startDate = moment({
      ...selectedDayRange.from,
      month: selectedDayRange?.from?.month! - 1,
    });
    const endDatethis = moment({
      ...selectedDayRange.to,
      month: selectedDayRange?.to?.month! - 1,
    });
    if (
      selectedDayRange.from &&
      selectedDayRange.to &&
      startDate.format("DD/MM/YYYY") !== endDatethis.format("DD/MM/YYYY")
    ) {
      if (endDatethis.diff(startDate, "days") + 1 >= 3) {
        navigate("/nowa-rezerwacja", {
          state: {
            apartment_id: Number(id),
            date_from: startDate.format("DD/MM/YYYY"),
            date_to: endDatethis.format("DD/MM/YYYY"),
          },
        });
        return true;
      }
      setErrorMessage("Minimalny czas rezerwacji to 3 dni!");
      return false;
    }
    setErrorMessage("Nie zaznaczyleś dni!");
    return true;
  };
  return (
    <>
      <div className="reservation-container">
        <div className="header-div">
          <img
            src="https://azurespain.com/wp-content/themes/luviana/images/header_default.png"
            alt=""
          />
          <Skeleton isLoaded={!loading}>
            <h1>{infoAboutApartment?.name}</h1>
          </Skeleton>
          <Skeleton isLoaded={!loading}>
            <GaleryCarousel autoSkip={false}>
              {infoAboutApartment?.images?.map((image) => {
                return (
                  <img key={image.id} src={urls.IMAGE(image.name)} alt="" />
                );
              })}
            </GaleryCarousel>
          </Skeleton>
        </div>
        <div className="informations-about-apartament">
          <motion.div
            initial="hidden"
            viewport={{ amount: isSmallScreen ? 0 : 0.5, once: true }}
            transition={{ duration: 0.4 }}
            whileInView={"visable"}
            variants={animationVariant}
            className="informations"
          >
            <h2>Opis</h2>
            <SkeletonText isLoaded={!loading} noOfLines={20} spacing={4}>
              <p>{infoAboutApartment?.description}</p>
            </SkeletonText>
            <Skeleton isLoaded={!loading}>
              <div className="page-navigation-div">
                {pageChoose.map((pageElement, index) => {
                  return (
                    <button
                      key={index}
                      className={`page-navigation-button ${
                        page === pageElement.pageStatement ? "selected" : ""
                      }`}
                      onClick={(e) => {
                        setPage(pageElement.pageStatement);
                      }}
                    >
                      {pageElement.title}
                    </button>
                  );
                })}
              </div>
              <ApartmentContext.Provider value={infoAboutApartment}>
                <AnimatePresence mode="wait">
                  <motion.div
                    className="more-information-div"
                    key={page}
                    variants={animationOnNavigationChange}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    transition={{ duration: 0.15 }}
                  >
                    <PageController page={page} />
                  </motion.div>
                </AnimatePresence>
              </ApartmentContext.Provider>
            </Skeleton>
          </motion.div>
          <motion.div
            className="reservate-box-div"
            initial="hidden"
            viewport={{ amount: !isSmallScreen ? 0.5 : 0, once: true }}
            transition={{ duration: 0.4 }}
            whileInView={"visable"}
            variants={animationVariantForCallendar}
          >
            <SkeletonText isLoaded={!loading} noOfLines={1}>
              <p className="apartment-price-one-day-rent">
                {
                  infoAboutApartment?.price?.[
                    (moment().month() +
                      1) as keyof typeof infoAboutApartment.price
                  ]
                }
                <span className="currency-span">€</span>{" "}
                <span className="days-span">/ za 1 dzień</span>
              </p>
            </SkeletonText>
            <Skeleton isLoaded={!loading}>
              <Calendar
                calendarTodayClassName="today-callendar"
                disabledDays={reservationDates}
                value={selectedDayRange}
                calendarRangeBetweenClassName="between-selected-range"
                calendarRangeEndClassName="selected-range"
                calendarRangeStartClassName="selected-range"
                onChange={setSelectedDayRange}
                shouldHighlightWeekends
                minimumDate={current_date()}
                maximumDate={{
                  ...current_date(),
                  year: current_date().year + 2,
                }}
              />
            </Skeleton>
            <div className="info-box">
              <h2>
                Chwilowo rezerwacje prowadzimy drogą mailową bądz telefoniczną
              </h2>
              <div className="info-container">
                <p className="phone">
                  <FontAwesomeIcon icon={faPhone} />
                  Telefon: <a href="tel:+48510777235">+48 510 777 235</a>
                </p>
                <p className="email">
                  <FontAwesomeIcon icon={faEnvelope} />
                  Email:{" "}
                  <a href="mailto:casa@teneryfa-24.eu">casa@teneryfa-24.eu</a>
                </p>
              </div>
            </div>
            {/* {errorMessage && <p className="error-message">{errorMessage}</p>}
            <button
              className="make-reservation-button"
              onClick={() => checkIfCan()}
            >
              Załóż nowa rezerwacje
            </button> */}
          </motion.div>
        </div>
      </div>
    </>
  );
};

export default Reservation;
