/* eslint-disable @typescript-eslint/no-explicit-any */
import "./FullImage.scss";
import { useSwipeable } from "react-swipeable";
import React, { FunctionComponent, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faAngleRight,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { AnimatePresence, motion } from "framer-motion";

interface Props {
  children: React.ReactElement[] | React.ReactElement;
}

const FullImage: FunctionComponent<Props> = ({ children }) => {
  const isItMoreChildrens = Array.isArray(children);

  const images = isItMoreChildrens ? children : [children];

  const [currentImage, setCurrentImage] = useState(0);
  const [isShow, setIsShow] = useState(false);
  const [zoomed, setZoomed] = useState(false);
  const open = () => {
    setIsShow(true);
  };

  const close = () => {
    setIsShow(false);
  };

  const nextPage = () => {
    console.log("next page");
    setCurrentImage((state) => (state == images.length ? state : state + 1));
  };

  const previousPage = () => {
    console.log("previous page");
    setCurrentImage((state) => (state > 1 ? state - 1 : 1));
  };

  const canSkip = () => {
    return currentImage < images.length;
  };

  const canPrevious = () => {
    return currentImage > 1;
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      if (currentImage != images.length) {
        setCurrentImage((state) => state + 1);
      }
    },
    onSwipedRight: () => {
      if (currentImage > 1) {
        setCurrentImage((state) => state - 1);
      }
    },
  });

  useEffect(() => {
    if (isShow) {
      document.body.style.overflow = "hidden";
      return;
    }
    document.body.style.overflow = "auto";
  }, [isShow]);

  useEffect(() => {
    setZoomed(false);
  }, [currentImage]);

  useEffect(() => {
    const detectEscapeAndClose = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        close();
        console.log("close");
      }
    };
    if (!isShow) {
      return;
    }

    window.addEventListener("keydown", detectEscapeAndClose);

    return () => {
      window.removeEventListener("keydown", detectEscapeAndClose);
    };
  }, [isShow]);

  const animationVarinat = {
    initial: {
      opacity: 0,
    },
    visable: {
      opacity: 1,
      transition: {
        duration: 0.2,
      },
    },
  };

  const findImageOrVideo: any = (child: React.ReactElement) => {
    if (child.type == "video" || child.type == "img") {
      return child;
    }
    let next_children = null;
    if (Array.isArray(child.props.children)) {
      for (let i = 0; i < child.props.children.length; i++) {
        if (
          child.props.children[i].type == "video" ||
          child.props.children[i].type == "img"
        ) {
          next_children = child.props.children[i];
          break;
        }
        if (Array.isArray(child.props.children[i].props.children)) {
          next_children = child.props.children[i];
        }
      }
    } else {
      if (
        child.props.children.type == "video" ||
        child.props.children.type == "img"
      ) {
        next_children = child.props.children;
      } else {
        next_children = child.props.children.props.children;
      }
    }
    return findImageOrVideo(next_children);
  };

  const displayFile = (children: React.ReactElement) => {
    const file_to_display = findImageOrVideo(children);

    console.log(file_to_display);

    if (!file_to_display) {
      return;
    }

    return isItMoreChildrens ? (
      file_to_display.type == "video" ? (
        <video src={file_to_display.props.src} autoPlay controls />
      ) : (
        <img src={file_to_display.props.src} alt="full-image" />
      )
    ) : (
      children
    );
  };

  return (
    <>
      {isShow &&
        createPortal(
          <div {...handlers} className="full-image-container">
            <button className="close-button" onClick={() => close()}>
              <FontAwesomeIcon icon={faXmark} />
            </button>
            <AnimatePresence mode="wait">
              <motion.div
                variants={animationVarinat}
                initial="initial"
                animate="visable"
                exit="initial"
                key={currentImage}
                className={`full-image ${zoomed ? "zoomed" : ""}`}
                onDoubleClick={() => setZoomed((state) => !state)}
              >
                {displayFile(
                  isItMoreChildrens ? children[currentImage - 1] : children
                )}
              </motion.div>
            </AnimatePresence>
            <div className="buttons-group">
              <button
                className="previous-button"
                disabled={!canPrevious()}
                onClick={() => previousPage()}
              >
                <FontAwesomeIcon icon={faAngleLeft} />
              </button>
              <button
                disabled={!canSkip()}
                className="skip-button"
                onClick={() => nextPage()}
              >
                <FontAwesomeIcon icon={faAngleRight} />
              </button>
            </div>
            {/* <div className="bottom-image-select">
              {isItMoreChildrens &&
                children.map((child, index) => {
                  return (
                    <div
                      key={index}
                      className={`image-select ${
                        index === currentImage ? "active" : ""
                      }`}
                      onClick={() => setCurrentImage(index + 1)}
                    >
                      {child}
                    </div>
                  );
                })}
            </div> */}
          </div>,
          document.body
        )}
      <>
        {isItMoreChildrens
          ? children.map((child, index) => {
              return React.cloneElement(child, {
                onClick: (e: React.MouseEvent) => {
                  setCurrentImage(index + 1);
                  open();
                },
              });
            })
          : React.cloneElement(children, {
              onClick: () => {
                open();
              },
            })}
      </>
    </>
  );
};

export default FullImage;
