import "./ModalContact.scss";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

type Props = {
  children: React.ReactElement;
};

const ModalContact: React.FC<Props> = ({ children }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      {React.cloneElement(children as React.ReactElement, { onClick: onOpen })}

      <Modal isOpen={isOpen} onClose={onClose} size={"3xl"}>
        <ModalOverlay />
        <ModalContent className="modal-contact-container">
          <ModalHeader>Kotntakt</ModalHeader>
          <ModalCloseButton />
          <ModalBody className="modal-body">
            <a className="phone" href="tel:+48510777235">
              <FontAwesomeIcon icon={faPhone} /> Telefon: +48 510 777 235
            </a>
            <a className="email" href="mailto:casa@teneryfa-24.eu">
              <FontAwesomeIcon icon={faEnvelope} /> E-mail: casa@teneryfa-24.eu
            </a>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalContact;
