import "./App.scss";
import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import {
  Reservation,
  Index,
  ReservationDetails,
  FindReservation,
  CancelRequest,
} from "./Pages";
import { ChakraProvider } from "@chakra-ui/react";
import Header from "./components/UI/Header/header";
import Footer from "./components/UI/Footer/Footer";
import NotFound from "./Pages/NotFound/NotFound";
import Galery from "./Pages/Galery/Galery";
const App = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <ChakraProvider>
      <Header />
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/apartament/:id" element={<Reservation />} />
        <Route path="/galeria" element={<Galery />} />
        {/* <Route path="/nowa-rezerwacja" element={<Checkout />} /> */}
        <Route path="/twoja-rezerwacja/" element={<FindReservation />}>
          <Route path=":uuid" element={<ReservationDetails />} />
        </Route>
        <Route path="/anuluj-rezerwacje/:uuid" element={<CancelRequest />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </ChakraProvider>
  );
};

export default App;
