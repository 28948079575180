import React from "react";
import "./header.scss";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useMediaQuery,
  Tooltip,
} from "@chakra-ui/react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import MenuHamburger from "../MenuHamburger/MenuHamburger";
import DarkIcon from "../../../assets/images/new-logo.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import axios, { AxiosError } from "axios";
import requestToApi from "../../axios";
import { Props } from "./interfaces";
import { Apartment, ThinApartmentInfo } from "../../../types";

const Header: React.FC<Props> = (props) => {
  const bgTransparent = props.bgTransparent || false;
  const [isPhone] = useMediaQuery("(max-width: 1000px)");
  const [listOfApartments, setListOfApartments] = useState<ThinApartmentInfo[]>(
    []
  );
  const location = useLocation();
  const navigateTo = useNavigate();
  useEffect(() => {
    const getApartments = async () => {
      const fetchApartments = await requestToApi
        .get("apartments")
        .catch((err: AxiosError) => err.response);

      if (fetchApartments?.data.success) {
        setListOfApartments(fetchApartments.data.apartments);
      }
    };
    getApartments();
  }, []);

  return (
    <header className={`header ${bgTransparent ? "transparent" : ""}`}>
      <img
        onClick={() => navigateTo("/")}
        src={DarkIcon}
        alt=""
        className="logo-icon"
      />
      {!isPhone ? (
        <ul className="list-of-links-header">
          <NavLink
            className={`menu-link ${bgTransparent ? "black" : ""} `}
            to="/"
          >
            Strona Główna
          </NavLink>
          <NavLink
            className={`menu-link ${bgTransparent ? "black" : ""} `}
            to="/galeria"
          >
            Galeria
          </NavLink>

          <Menu>
            <MenuButton
              className={`menu-link ${bgTransparent ? "black" : ""} ${
                location.pathname.includes("/apartament")
                  ? "active can-click"
                  : ""
              } `}
            >
              Apartamenty
            </MenuButton>
            <MenuList>
              {listOfApartments.length === 0 && (
                <p className="error-message">Brak Apartamentów</p>
              )}
              {listOfApartments?.map((apartment, index) => {
                const { id, name } = apartment;
                return (
                  <NavLink
                    key={index}
                    className="links-to-appartaments"
                    to={`/apartament/${id}`}
                  >
                    <MenuItem
                      className={`apartment-button ${
                        location.pathname === `/apartament/${id}`
                          ? "selected"
                          : ""
                      }`}
                    >
                      {location.pathname === `/apartament/${id}` && (
                        <FontAwesomeIcon icon={faCaretRight} />
                      )}{" "}
                      {name}
                    </MenuItem>
                  </NavLink>
                );
              })}
            </MenuList>
          </Menu>
          <Tooltip
            label={
              listOfApartments.length >= 1
                ? ""
                : "Narazie, nie ma żadnych apartamentów do wynajęcia"
            }
          >
            <NavLink
              className={`button-reservation ${
                listOfApartments.length === 0 ? "no-apartments-avilvable" : ""
              }`}
              to={`/apartament/${listOfApartments[0]?.id}`}
            >
              Rezerwuj
            </NavLink>
          </Tooltip>
        </ul>
      ) : (
        <MenuHamburger list_of_apartments={listOfApartments} />
      )}
    </header>
  );
};

export default Header;
