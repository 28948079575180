import "./BasicInformations.scss";
import React from "react";
import { useContext } from "react";
import ReservationContext from "../../../../context/ApartmentContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBed,
  faPersonShelter,
  faSquare,
  faSuperscript,
  faToilet,
} from "@fortawesome/free-solid-svg-icons";

const BasicInformations = () => {
  const apartment = useContext(ReservationContext);
  const basic_informations = apartment?.["basic-informations"];

  const { bathrooms, bedrooms, dimensions, rooms } = basic_informations!;

  return (
    <div className="basic-informations-container">
      <h3>Podstawowe informacje</h3>
      <div className="info">
        <div>
          <p className="topic">
            <FontAwesomeIcon icon={faToilet} /> Łazienki
          </p>
          <p className="value">{bathrooms}</p>
        </div>
        <div>
          <p className="topic">
            <FontAwesomeIcon icon={faBed} /> Sypialnie
          </p>
          <p className="value">{bedrooms}</p>
        </div>
        <div>
          <p className="topic">
            <FontAwesomeIcon icon={faPersonShelter} /> Pokoje
          </p>
          <p className="value">{rooms}</p>
        </div>
        <div>
          <p className="topic">
            <FontAwesomeIcon icon={faSuperscript} /> Wymiary
          </p>
          <p className="value">
            {dimensions}m<sup>2</sup>
          </p>
        </div>
      </div>
    </div>
  );
};

export default BasicInformations;
