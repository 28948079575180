import { useEffect, useState } from "react";
import "./CancelRequest.scss";
import { NavLink, useParams } from "react-router-dom";
import requestToApi from "../../components/axios";
import urls from "../../urls";
import Spinner from "../../components/UI/Spinner/Spinner";
import { TypeOfMessageFromCancelRequest } from "../../types";

// IMAGES IMPORT

import CancelCompleteImage from "../../assets/storyset/Heartbroken-bro.svg";
import ErrorImage from "../../assets/storyset/Forgot password-bro.svg";

const CancelRequest: React.FC = () => {
  const { uuid } = useParams();

  const [cancelReservationSuccess, setCancelReservationSuccess] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [message, setMessage] = useState<string>("");
  const [typeOfMessage, setTypeOfMessage] =
    useState<TypeOfMessageFromCancelRequest>();

  useEffect(() => {
    const init = async () => {
      const response = await requestToApi
        .post(urls.CANCEL_RESERVATION, { cancel_uuid: uuid })
        .catch((err) => err.response);

      if (response.data.success) {
        setCancelReservationSuccess(true);
        setLoading(false);
        return;
      }
      setLoading(false);
      setMessage(response.data.message);
      setTypeOfMessage(response.data.type);
    };
    init();
  }, []);
  return (
    <div className="cancel-request-container">
      <h1>Anulowanie Rezerwacji</h1>
      {loading ? (
        <div className="loading-div">
          <Spinner />
          <p>Przetwarzanie żądania</p>
        </div>
      ) : cancelReservationSuccess ? (
        <div className="cancel-completed">
          <p>Twoja Rezerwacja została anulowana pomyślnie</p>
          <img src={CancelCompleteImage} alt="" />
          <NavLink className="button-back" to="/">
            Strona Główna
          </NavLink>
        </div>
      ) : (
        <div className="cancel-failed">
          <p>{message}</p>
          <img src={ErrorImage} alt="" />
        </div>
      )}
    </div>
  );
};

export default CancelRequest;
