/* eslint-disable react-hooks/exhaustive-deps */
import "./index.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBath,
  faWifi,
  faMapPin,
  faLocationDot,
  faUtensils,
  faBuilding,
} from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { imagesAnimationSchema, textAnimationSchema } from "./aniamtionSchemas";
import { useToast, useMediaQuery } from "@chakra-ui/react";
import Carousel from "../../components/UI/Carousel/Carousel";
import requestToApi from "../../components/axios";
import urls from "../../urls";
import { Apartment } from "../../types";

// images import
import IndexImage1 from "../../assets/images/Index Images/index-image1.jpg";
import IndexImage2 from "../../assets/images/Index Images/index-image2.jpg";
import IndexImage3 from "../../assets/images/Index Images/index-image3.jpg";
import { ModalInformation } from "../../components";

const Index: React.FC = () => {
  const [apartamentsImages, setApartamentsImages] = useState<Apartment[]>([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const toast = useToast({ isClosable: true });
  const [isPhone] = useMediaQuery("(max-width: 650px)");
  useEffect(() => {
    document.title = "Strona Główna | Beztroskiezycie";
    const getApartmentsImages = async () => {
      setIsLoaded(false);
      const response = await requestToApi
        .get(urls.APARTMENTS_IMAGES)
        .catch((err) => err.response);
      if (response.data.success) {
        setApartamentsImages(response.data.apartments);
        setIsLoaded(true);
        return;
      }
      toast({
        title: "Błąd",
        description:
          "Nie udało się pobrać zdjęć apartamentów, spróbuj ponownie później",
        status: "error",
      });

      setIsLoaded(true);
    };
    getApartmentsImages();
  }, []);
  return (
    <>
      <div className="index-container">
        <div className="main-title-div">
          <div className="backdrop"></div>
          <div className="title-div">
            <h1 className="text-9xl">
              Apartamenty na Teneryfie <br />
              <span>San Miguel De Tajao </span>
            </h1>
            <p>Twój wymarzony wypoczynek na wyspie </p>
            {isLoaded && (
              <ModalInformation>
                <button className="about-button">Dowiedz sie więcej!</button>
              </ModalInformation>
            )}
          </div>
        </div>
        <div className="margin-div">
          <div className="description-about-apartments">
            <div className="description" id="wiecej">
              <h2>Apartamenty w Tajao</h2>
              <p>
                Zanurz się w słońcu Teneryfy w naszym niesamowitym domu
                wypoczynkowym? położonym przy samym oceanie w Tajao, malowniczej
                miejscowości rybackiej z uroczym portem, gdzie szum fal ukołysze
                Cię do snu. <br /> <br />
                <span>NASZ DOM: </span>Nasz piękny dom wypoczynkowy składa się z
                4 kondygnacji, z jednym dwupokojowym ekskluzywnym apartamentem
                na każdym piętrze. Każdy apartament oferuje niepowtarzalne
                widoki na ocean, czysty i nowoczesny design oraz pełen komfort i
                prywatność. W każdym apartamencie może zatrzymać się do 3-4
                osób, co daje możliwość zakwaterowania nawet 12-16 gości
                jednocześnie! <br /> <br />
                <span>OFERUJEMY: </span> 4 luksusowe apartamenty z widokiem na
                ocean• Miejsce dla 12 osób• Nowoczesna w pełni wyposażona
                kuchnia? w każdym apartamencie• Bezprzewodowy dostęp do
                internetu• Bliskość do lokalnych restauracji i atrakcji <br />{" "}
                <br />
                <span>INDYWIDUALNE WYPOCZYNKI I GRUPOWE WYJAZDY: </span> Czy
                szukasz miejsca na romantyczny wyjazd we dwoje, wypoczynek z
                rodziną czy wakacje z grupą przyjaciół? Nasz dom wypoczynkowy
                jest idealnym wyborem dla każdego! <br /> <br />
                <span>REZERWACJE: </span> Czekamy na Ciebie przez cały rok! Nie
                zwlekaj, skontaktuj się z nami i zarezerwuj swój termin na
                niezapomniane wakacje! <br /> <br />
                <span>KONTAKT: </span> Zadzwoń do nas:Napisz do nas: Dom
                Wypoczynkowy? w Tajao - Twoje idealne miejsce na wypoczynek na
                Teneryfie!
              </p>
            </div>
            <AnimatePresence>
              <div className="images-div">
                <motion.img
                  src={IndexImage1}
                  alt=""
                  initial="hidden"
                  variants={imagesAnimationSchema(0, 0)}
                  viewport={{ amount: 0.3, once: true }}
                  whileInView={"visible"}
                />
                <motion.img
                  src={IndexImage2}
                  alt=""
                  initial="hidden"
                  whileInView={"visible"}
                  viewport={{ amount: 0.3, once: true }}
                  variants={imagesAnimationSchema(isPhone ? 0 : 0.2, 0)}
                />
                <motion.img
                  src={IndexImage3}
                  alt=""
                  initial="hidden"
                  whileInView={"visible"}
                  viewport={{ amount: 0.3, once: true }}
                  variants={imagesAnimationSchema(isPhone ? 0 : 0.4, 0)}
                />
              </div>
            </AnimatePresence>
          </div>
        </div>
        <div className="margin-div">
          <div className="what-we-offer">
            <h2>Co oferujemy</h2>
            <div className="offer-div">
              <motion.div
                initial="hidden"
                whileInView={"visible"}
                viewport={{ amount: 0.5, once: true }}
                variants={textAnimationSchema(0, 0)}
                className="offer"
              >
                <FontAwesomeIcon className="offer-image" icon={faLocationDot} />
                <h3>Doskonała lokalizacja</h3>
                <p>
                  Nasz apartament to idealne miejsce na relaks, zaledwie 15
                  metrów od brzegu oceanu, 15 km od lotniska, z dogodnym
                  dostępem do komunikacji publicznej. Dodatkowo, znajdujemy się
                  tylko 100 metrów od lokalnego sklepu spożywczego, w otoczeniu
                  malowniczych szlaków turystycznych, a jednocześnie zaledwie 15
                  km od plaży w Medano.
                </p>
              </motion.div>
              <motion.div
                initial="hidden"
                whileInView={"visible"}
                viewport={{ amount: 0.5, once: true }}
                variants={textAnimationSchema(isPhone ? 0 : 0.3, 0)}
                className="offer"
              >
                <FontAwesomeIcon className="offer-image" icon={faUtensils} />
                <h3>Raj dla podniebienia i dla wyciszenia</h3>
                <p>
                  Znajdujemy się w małej, kameralnej miejscowości, gdzie panuje
                  cisza i spokój, idealna do wypoczynku. Nasza lokalizacja to
                  także raj dla smakoszy, z łatwym dostępem do najbardziej
                  znanych na Teneryfie restauracji serwujących owoce morza. Dla
                  rodzin z dziećmi przygotowaliśmy plac zabaw, a także w pobliżu
                  znajduje się nowoczesne boisko sportowe, zapewniając rozrywkę
                  dla każdego.
                </p>
              </motion.div>
              <motion.div
                initial="hidden"
                whileInView={"visible"}
                viewport={{ amount: 0.5, once: true }}
                variants={textAnimationSchema(isPhone ? 0 : 0.5, 0)}
                className="offer"
              >
                <FontAwesomeIcon className="offer-image" icon={faBuilding} />
                <h3>Apartamenty... a co w środku</h3>
                <p>
                  Nasz apartament to idealne rozwiązanie dla tych, którzy cenią
                  sobie niezależność i wygodę. Oferujemy 1 studio oraz 3 w pełni
                  wyposażone, samodzielne apartamenty, gdzie każdy ma swój aneks
                  kuchenny oraz własną łazienkę z kabiną prysznicową. Nie musisz
                  martwić się o pranie, ponieważ w apartamencie znajdziesz
                  pralkę. Dla komfortu naszych gości przygotowaliśmy także
                  oddzielne sypialnie. Wspólny pokój dzienny z aneksem kuchennym
                  oraz smart TV to doskonałe miejsce do spotkań i relaksu. Na
                  miejscu znajdziesz pościel i ręczniki, co sprawi, że poczujesz
                  się jak w domu.
                </p>
              </motion.div>
              <motion.div
                initial="hidden"
                whileInView={"visible"}
                viewport={{ amount: 0.5, once: true }}
                variants={textAnimationSchema(isPhone ? 0 : 0.7, 0)}
                className="offer"
              >
                <FontAwesomeIcon className="offer-image" icon={faWifi} />
                <h3>Darmowe WIFI</h3>
                <p>
                  Zapewniamy naszym gościom szybki, szerokopasmowy internet
                  WiFi, dostępny bez dodatkowych opłat, abyście mogli cieszyć
                  się bezproblemowym połączeniem podczas całego
                  pobytu.Zapewniamy naszym gościom szybki, szerokopasmowy
                  internet WiFi, dostępny bez dodatkowych opłat, abyście mogli
                  cieszyć się bezproblemowym połączeniem podczas całego pobytu.
                </p>
              </motion.div>
            </div>
          </div>
        </div>
        <div className="margin-div">
          <div className="gallery">
            <h2>Galeria</h2>
            <div className="gallery-div">
              {apartamentsImages.map((apartment, index) => {
                return (
                  <div key={index} className="apartment-iamges-div">
                    <h3>{apartment.name}</h3>
                    <Carousel>
                      {apartment.images.slice(0, 5).map((image, index) => {
                        return (
                          <img
                            key={index}
                            loading="lazy"
                            src={urls.IMAGE(image.name)}
                            alt=""
                          />
                        );
                      })}
                    </Carousel>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
