import { useToast } from "@chakra-ui/react";
import { Navigate } from "react-router-dom";

const NotFound: React.FC = () => {
  const toast = useToast();

  toast({
    title: "Nie znaleziono strony",
    description: "Przepraszamy, ale strona, której szukasz nie istnieje",
    status: "error",
    duration: 5000,
    isClosable: true,
  });

  return <Navigate to="/" />;
};

export default NotFound;
