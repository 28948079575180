import "./FindReservation.scss";
import { Outlet, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import TransitionPage from "../../components/Transition";

const FindReservation: React.FC = () => {
  const [reservatiionNumber, setReservationNumber] = useState<string>("");
  const navigate = useNavigate();

  const submit_form = (e: React.FormEvent) => {
    e.preventDefault();

    if (reservatiionNumber.length < 1) {
      return;
    }
    navigate(`/twoja-rezerwacja/${reservatiionNumber}`);
  };

  useEffect(() => {
    document.title = "Znajdź Rezerwacje | Beztroskiezycie";
  });
  return (
    <>
      <div className="find-my-reservation-container">
        <div className="information">
          <h1>Znajdz rezerwacje</h1>
          <p>
            Wpisz numer rezerwacji, który otrzymałeś na maila po złożeniu
            rezerwacji na naszej stronie.
          </p>
        </div>
        <form onSubmit={submit_form} className="find-my-reservation-form">
          <input
            type="text"
            value={reservatiionNumber}
            onChange={(e) => setReservationNumber(e.target.value)}
          />
          <button>Szukaj</button>
        </form>
      </div>
      <Outlet />
    </>
  );
};

export default FindReservation;
