import "./Price.scss";
import React from "react";
import { useContext } from "react";
import ReservationContext from "../../../../context/ApartmentContext";

const Price = () => {
  const infoAboutApartment = useContext(ReservationContext);
  const priceList = Object.entries(infoAboutApartment?.price!); // index 0 - month, index 1 - price
  const months: String[] = [
    "Styczeń",
    "Luty",
    "Marzec",
    "Kwiecień",
    "Maj",
    "Czerwiec",
    "Lipiec",
    "Sierpień",
    "Wrzesień",
    "Październik",
    "Listopad",
    "Grudzień",
  ];
  return (
    <div className="montly-prices-div">
      <h3>Ceny za jedną noc</h3>
      <div className="montly-prices">
        {priceList.map((priceList, index: number) => {
          return (
            <div className="montly-price" key={index}>
              <h4 className="month">{months[index]}</h4>
              <p className="price">{priceList[1]} €</p>
            </div>
          );
        })}
      </div>
      <p className="info-important">Do ceny rezerwacji doliczana jest jednorazowo kwota <span>80€</span> na pokrycie kosztów przygotowania apartamentu na Państwa przyjazd, jak również posprzątania po Państwa pobycie.</p>
    </div>
  );
};

export default Price;
